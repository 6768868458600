import Robot1 from "../../images/robot1.jpg";
import Robot2 from "../../images/robot2.jpg";
import Robot3 from "../../images/robot3.jpg";

const reviewData = [
  {
    id: 1,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot1,
    productName: "John",
  },
  {
    id: 2,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot2,
    productName: "Gabie",
  },
  {
    id: 3,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot3,
    productName: "Dow",
  },

  {
    id: 4,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot1,
    productName: "John",
  },
  {
    id: 5,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot2,
    productName: "Gabie",
  },
  {
    id: 6,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot3,
    productName: "Dow",
  },

  {
    id: 7,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot1,
    productName: "John",
  },
  {
    id: 8,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot2,
    productName: "Gabie",
  },
  {
    id: 9,
    smallDesc:
      "I shall see the face of Mars, anyhow, and that will be a rare experience. It seems to me that a view of the heavenly bodies.",
    image: Robot3,
    productName: "Dow",
  },
];

export default reviewData;
